import { createContext, useContext, useState, useEffect, FC } from 'react';
const WindowContext = createContext({});

export const useSize = () => {
  return useContext(WindowContext);
}
export const WindowProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const getWindowInfo = () => {
    return {
      width:window.innerWidth,
      height: window.innerHeight,
    };
  }
  const [windowInfo,setWindowInfo] = useState(getWindowInfo());
  useEffect(() => {
    const windowSize = () => {
      setWindowInfo(getWindowInfo());
    }
    window.addEventListener('resize', windowSize);
    return () => {
      window.removeEventListener('resize', windowSize);
    }
  },[])

  const value:any = {
    windowInfo
  };
  return (
    <WindowContext.Provider value={value}>
      {children}
    </WindowContext.Provider>
  );
}