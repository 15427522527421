import { useRoutes } from 'react-router-dom';
import routes from './routes/Index';
import { Suspense } from 'react';
import { AuthProvider } from './context/AuthContext';
import { WindowProvider } from './context/WindowContext'
function AppRoutes() {
  return useRoutes(routes);
}
const App: React.FC = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <AuthProvider>
        <WindowProvider>
          <AppRoutes />
        </WindowProvider>
      </AuthProvider>
    </Suspense>
  )
}
export default App;
